import React from 'react'
import { Grid, Box } from '@material-ui/core'

import Layout from '../../components/layout'
import AuthForm from '../../components/page/admin/auth/authForm'

export default function LoginPage () {
  return (
    <Layout>
      <Box py={4} />
      <Grid container>
        <Grid item xs={3} sm={4} />
        <Grid item xs={6} sm={4}>
          <AuthForm />
        </Grid>
        <Grid item xs={3} sm={4} />
      </Grid>
    </Layout>
  )
}

import React from 'react'
import { Card, CardContent, Box, Typography, Button } from '@material-ui/core'
import { TextField } from 'formik-material-ui'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'
import { navigate } from 'gatsby'

import {
  POSTLogin,
  SetUserToken,
  SetTokenExpiryTime
} from '../../../../client/client'

export default function AuthForm ({ type }) {
  return (
    <Card>
      <CardContent>
        <Typography variant='h4' align='center'>
          Login
        </Typography>
        <FormContainer type={type} />
      </CardContent>
    </Card>
  )
}

function FormContainer ({ type }) {
  let validate = Yup.object().shape({
    password: Yup.string().required('Required')
  })
  return (
    <Box
      display='flex'
      justifyContent='center'
      flexDirection='column'
      alignItems='center'
      textAlign='center'
    >
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        textAlign='center'
      >
        <Formik
          style={{ border: '1px solid black' }}
          initialValues={{ password: '' }}
          onSubmit={submit}
          validationSchema={validate}
          render={FormInner}
        />
      </Box>
    </Box>
  )
  // ********************************************************
  // submit as a function of the type
  function submit (values, actions) {
    return POSTLogin(values.password).then(
      function onFulfilled (response) {
        actions.setSubmitting(false)
        SetUserToken(response.data.token)
        navigate(`/admin/unmatched`)
      },
      function onRejected (response) {
        actions.setSubmitting(false)
        alert('invalid credentials. please try again')
      }
    )
  }
}

function FormInner ({ errors, status, touched, isSubmitting, setFieldValue }) {
  return (
    <Form>
      <Field
        component={TextField}
        error={errors.password}
        type='password'
        name='password'
        label='Password'
      />
      <br />
      <br />
      <Button
        type='submit'
        variant='contained'
        color='secondary'
        disabled={isSubmitting}
      >
        Login
      </Button>
    </Form>
  )
}
